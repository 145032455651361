
.container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .imageContainer {
        width: 58%;
        height: 100%;
        flex: 0 0 auto;

        .loginImage {
            width: 100%;
            height: 100%;
        }
    }

    .formContainer {
        width: 42%;
        display: flex;
        flex: 0 0 auto;
        background-color: #f8f9fe;

        .loginCard {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            flex-direction: column;
        }

        .heading>h2 {
            color: #7366ff;
            text-decoration: none;
            letter-spacing: 1px;
            font-size: 32px;
            line-height: 1.2;
            text-align: center;
        }

        .formBox {
            background: white;
            padding: 40px;
            width: 350px;

            .title {
                font-size: 24px;
                margin: 0 0 5px;
            }

            .description {
                margin: 0 0 25px;
                font-size: 14px;
                color: #898989;
            }
        }

        .login_error {
            color: red;
            font-weight: 500;
            text-align: center;
            font-size: 16px;
            margin-top: 12px;
        }
    }
}