.subheader {
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    vertical-align: middle;
    background: #fff;
    width: 100%;
    margin: -18px 0 16px -20px;

    .description {
        margin: 1em 0 1em 0;

        div {
            display: inline-block;
            margin-left: 12px;
            vertical-align: middle;
        }
    }
}