.filter_container {
    display: flex;
    flex-wrap: wrap;
    
    .filter_group {
        margin-left: 12px;
        margin-right: 12px;

        .inputField {
            box-sizing: border-box;
            margin-top: 12px;
            margin-bottom: 12px;
            background: white;
            border-radius: 4px;
            border: 1px solid #ced4da;
        }
    }

    .apply_button {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        padding: 0.375rem 1.75rem;
        margin-left: 12px;
        margin-right: 12px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
