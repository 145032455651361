.paginate_container {
    text-align: center;
    margin-top: 12px;
}


.download_button {
    background-color: #7366ff!important;
    border-color: #7366ff !important;
    padding: 0.375rem 1.75rem;
    margin-left: 12px;
    margin-right: 12px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 12px;
}
