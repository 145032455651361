

@tailwind base;

@tailwind utilities;


.main {
         
          
    overflow: auto;
    overflow-x: hidden;
  
    
    padding: 25px;

    border-radius: 0px;
    width: 100%;
    padding-bottom: 15px !important;

 
}
.top_color {
    height: 165px;
    background: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    border-radius: 0px;
}

    
.heading {
    padding-bottom: 25px;
    text-align: center;
    
    span {
        vertical-align: sub;
        color: #fff;
        font-size: 25px;
        line-height: 19px;
        font-weight: 1000;
        letter-spacing: 1px !important;
    }
}

    
.lang_list {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 10px 10px 20px 10px;
    color: rgba(255, 255, 255, 0.65);
    position: absolute;
    top: 5px;
    /* left: 50%; */
    right: 0;
    /* text-align: right; */
    padding: 0 10px 0;
    font-size: 16px;
    gap: 15px;

    span {
        cursor: pointer;
    }
}

    .container {
      
        font-size: 16px;
        width: 100%;

    



    
        .payment_info {
            width: 330px;
            margin: auto;
            padding: 20px 10px;
            border: 1px solid #ddd;
            height: auto;
            background: #fff;
            border-radius: 26px;
            text-align: center;
            line-height: 1.5;
            
    
            .ref_info {
                display: flex;
                margin-bottom: 20px;
                color: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
    
                .ref {
                    flex: 2;
                    text-align: left;
                }
    
                .timer {
                    flex: 0.5;
                    text-align: right;
                }
    
                img {
                    vertical-align: bottom;
                }
            }
        }
    
        .qr_container {
            .title_text {
                font-weight: bold;
                padding-bottom: 16px;
            }
    
            .qr_image {
                aspect-ratio: 1/1;
                object-fit: contain;
                width: 100%;
                height: 275px;
                margin-bottom: 16px;
            }
    
            .downloadQR {
                cursor: pointer;
                width: 75%;
                height: 53px;
                border: none;
                background: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
                border-radius: 29px;
                color: #ffffff;
                width: 58%;
                height: 44px;
                padding: 6px 10px;
                margin-bottom: 12px;
            }
        }
    
        .upi_container {
            display: flex;
            margin-bottom: 15px;
            margin-top: 10px;
    
            .upi {
                font-weight: bold;
                color: #29A94D;
                flex: 2;
                font-size: larger;
            }
            .copy_btn {
                cursor: pointer;
            }
        }
    
        .line_break {
            hr {
                border: 2px dashed #c7c7c7;
                border-style: none none dashed;
            }
        }
    
        .amount_container {
            padding: 0 30px;
    
            .amount {
                font-size: 24px;
                font-weight: bold;
                padding: 10px;
                color: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
    
                h2 {
                    margin: 0;
                }
            }
    
            .warning {
                padding-bottom: 10px;
                font-size: 12px;
            }
        }
    
        .upi_list {
            padding-top: 20px;
            padding-bottom: 0px;
            text-align: center;
    
            img {
                height: 30px;
                width: 30px;
                object-fit: contain;
                padding: 4px;
            }
        }
    
        .input_form {
            padding-top: 12px;
            padding-bottom: 10px;
            position: relative;
    
            input {
                box-sizing: border-box;
                width: 100%;
                height: 50px;
                left: 23px;
                top: 689px;
                background: #FFFFFF;
                border: 1.2px solid #EDEAEA;
                box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                padding: 12px;
                font-weight: 500;
                overflow-x: visible;
            }
    
            .submit_error {
                color: red;
                text-align: center;
                margin: 12px 0 0;
            }
        }
    
        .description {
            margin-top: 20px;
            line-height: 17px;
            font-weight: 400;
            font-size: 14px;
            color: #19284B;
        }
    
        .helper {
            margin-top: 27px;
            line-height: 17px;
            font-weight: 400;
            font-size: 14px;
            color: #19284B;
    
            .click_here {
                color: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
                font-weight: 600;
            }
        }
    
        .next_steps {
            padding-top: 50px;
    
            .note_container {
                padding: 20px 0;
                display: flex;
    
                .image_container {
                    background-color: #c9c9cf;
                    padding: 15px;
                    border-radius: 7px;
                    margin-right: 10px;
                }
    
                .note_text {
                    margin-top: 10px;
                }
            }
        }
    
        .secure_list {
            padding-top: 10px;
            padding-bottom: 10px;
    
            .secure_text {
                text-align: center;
                margin: 0;
                padding: 0;
                line-height: 1.2;
                font-weight: 600;
                font-size: 16px;
            }
    
            .img_container {
                width: 55px;
                height: 50px;
                display: flex;
                text-align: center;
                justify-items: center;
                margin: auto;
                justify-content: center;
            }
    
            .parnter_img {
                height: 50px;
                width: 50px;
                object-fit: contain;
                vertical-align: middle;
                margin: 12px;
            }
        }
    
        .spacer {
            height: 80px; 
            width: 100%
        }
    
        .submit_button {
            padding: 10px 10px 10px 10px;
            position: fixed;
            bottom: 0px;
            width: 100%;
            background: #fff;
            box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08);
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
    
            button {
                cursor: pointer;
                width: 75%;
                height: 53px;
                border: none;
                background: linear-gradient(90.04deg, #4f4cde 0.05%, #2c2ab7 99.97%);
                border-radius: 29px;
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }

