.menubar_container {
    position: fixed;
    z-index: 9;
    height: auto;
    line-height: inherit;
    background: #fff;
    width: 280px;
    text-align: left;
    transition: 0.3s;
    box-shadow: 0 0 21px 0 rgba(89,102,122,0.1);
    top: 94px;
    height: calc(100vh - 102px);
    overflow: hidden scroll;

    .menu_list {
        list-style: none;
        padding: 0;
        display: inline-block;
    }

    .list_item {
        display: block;
        width: 100%;
        padding: 0 20px;
        position: relative;

        &.sub_list {
            padding-left: 0;
        }

        a {
            font-weight: 500;
            padding: 12px 15px;
            position: relative;
            color: #222222;
            border-radius: 10px;
            transition: all 0.5s ease;
            display: block;

            &:hover {
                background-color: rgba(115,102,255,0.12);
                transition: all 0.3s ease;
                color: #9389ff;
            }

            .item_icon {
                margin-right: 10px;
                vertical-align: bottom;
                float: none;
                color: #2c323f;
                transition: all 0.3s ease;
            }

            .item_name {
                letter-spacing: 0.7px;
                font-family: Roboto;
                text-transform: capitalize;
                color: #2c323f;
                vertical-align: super;
            }

            .right_arrow {
                position: absolute;
                right: 15px;
                top: 18px;
                display: block;
            }
        }
    }
}