.table {
    width: 100% ;
    table-layout: fixed;
    border: 1px solid #efefef;
    border-collapse: collapse;
    box-sizing: content-box;
    line-height: 21px;

    .header {
        .header_row {
            .header_column {
                padding: 12px 0px 12px 10px;
                cursor: pointer;
                text-align: left;
                position: relative;
            }

            .sort_icon {
                position: absolute;
                right: 12px;
                bottom: 20px;
            }
        }
    }

    .body {
        .table_row {
            background-color: #f9f9f9;

            &:nth-child(odd) {
                background-color: #fff;
            }
        }

        .body_column {
            padding: 12px;
        }
    }
}