.badge {
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: 4px;
  }

  .badge-primary {
    color: #fff;
    background-color: #007bff;
  }
  
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc;
  }
  
  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }
  
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34;
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00;
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130;
  }
  
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
  }
  
  .badge-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
  }