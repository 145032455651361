.statement_management {
    .form_container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .formGroup {
            width: 300px !important;
            margin-right: 30px;
            margin-top: 12px;
        }

        button {
            background-color: #51bb25 !important;
            border-color: #51bb25 !important;
            padding: 0.375rem 1.75rem;
            margin-left: 12px;
            margin-right: 12px;
            color: white;
            font-size: 14px;
            font-weight: 400;
            margin-top: 12px;
            margin-bottom: 12px;
            width: 150px;
        }
    }
    .last_upload_time {
        text-align: center;
        padding: 16px;
        color: white;
        background: #7366ff;
        margin-top: 20px;
        border-radius: 12px;
    }

    .upload_error {
        color: red;
        font-weight: 500;
        text-align: center;
        font-size: 16px;
        margin-top: 12px;
    }

    .upload_success {
        color: green;
        font-weight: 500;
        text-align: center;
        font-size: 16px;
        margin-top: 12px;
    }

    .upload_progress {
        color: orange;
        font-weight: 500;
        text-align: center;
        font-size: 16px;
        margin-top: 12px;
    }
}

