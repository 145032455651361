.request_form {
    .subheader {
        margin: 0;
        width: 98%;
    }

    button {
        background-color: #51bb25 !important;
        border-color: #51bb25 !important;
        padding: 0.375rem 1.75rem;
        margin-left: 12px;
        margin-right: 12px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        margin-top: 12px;
        margin-bottom: 12px;
        width: 150px;
    }
}