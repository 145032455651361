.page_header {
    width: 100%;
    max-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 8;
    transition: .5s;
    background-color: #fff;
    box-shadow: 0px 4px 40px rgba(0,0,0,0.07);
    display: flex;
    justify-content: space-between;

    .logo_wrapper {
        padding: 27px 30px;

        a, h5 {
            font-size: 20px;
            margin: 0;
            text-decoration: none;
            color: #7366ff;
            font-weight: 500;
            letter-spacing: 1px;
        }
    }

    .header_menus {
        padding: 27px 30px;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-end;

        .maximize {
            cursor: pointer;
            padding: 8px 0 0 0;
            margin-right: 15px;
        }

        .user_info {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            position: relative;
        }

        .user_dropdown {
            margin-left: 15px;

            span {
                font-weight: 500;
                margin-top: 4px;
                display: inline-block;
            }
            p {
                font-size: 12px;
                line-height: 1;
                margin: 4px 0 0 0;
                
                i {
                    margin-left: 4px;
                }
            }
        }

        .user_actions {
            width: 160px;
            position: absolute;
            top: 38px;
            left: -12px;
            padding: 0;
            opacity: 1;
            transform: translateY(0px);
            visibility: visible;
            border-radius: 5px;
            overflow: hidden;
            transition: all linear 0.3s;
            background: white;
            list-style: none;

            li {
                cursor: pointer;
                padding: 12px;
                svg {
                    vertical-align: middle;
                    margin-right: 8px;
                }

                span {
                    color: #2c323f;
                    text-transform: uppercase;
                }
            }
        }
    }
}