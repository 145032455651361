.create_account {
    border-color: #51bb25;
    color: #51bb25;
    background-color: transparent;
    float: right;
    margin-bottom: 20px;

    &:hover {
        color: white;
        background-color: #3f901d !important;
        border-color: #3f901d !important;
    }
}