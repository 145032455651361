.paginate_container {
    text-align: center;
    margin-top: 12px;
}

.summary {
    margin-bottom: 16px;

    .title {
        font-weight: 500;
    }
}