.card_component {
    background: white;
    width: 230px;
    height: 120px;
    margin: 16px;
    border-radius: 12px;
    padding: 16px;
    
    .title {
        text-transform: uppercase;
        margin: 24px;
        font-weight: 500;
        font-size: 12px;
        color: #948f8f;
    }

    .value {
        margin-left: 24px;
        font-size: 18px;
        font-weight: 600;
    }
}