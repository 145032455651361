.thanks-container {
    margin: 0 auto;
    margin-top: 20%;
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    color: #7366ff;
    height: 100vh;

    .subtext {
        font-size: 32px;
    }
}